export const POST_PAGE_SELECTORS = {
  POST_HEADER: 'postHeader1',
  POST_CONTENT: 'postContent1',
  POST_FOOTER: 'postFooter1',
  RELATED_POSTS: 'relatedPosts1',
  COMMENTS: 'comments1',
  POST_PAGE_MULTISTATE_BOX: 'postStates',
  AVAILABLE_STATE: 'availableState',
  EMPTY_STATE: 'emptyState',
  NOT_FOUND: '404State',
  SEE_MORE_POSTS_BUTTON: 'button1',
} as const;

export const postPageWidgetDesign = {
  title: 'Post Page Design',
  tabs: [
    { label: 'Post Title', groups: { roles: ['title'] } },
    { label: 'Post writer', groups: { roles: ['text1'] } },
    {
      label: 'Post Counters',
      groups: {
        roles: [
          'likesDefaultNumber',
          'likesFilledNumber',
          'viewsNumber',
          'commentsNumber',
        ],
      },
    },
    {
      label: 'Body Text',
      groups: {
        compTypes: ['wixui.RichContentViewer'],
      },
    },
    {
      label: 'Metadata',
      groups: {
        roles: ['text5', 'text2', 'text3'],
      },
    },
    { label: 'Background', groups: { roles: ['postPageRoot'] } },
  ],
};
