import { ThunkDispatch } from 'redux-thunk';
import {
  getPostFeedMetadataPage,
  getPostFeedPage,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import {
  ArchiveOptions,
  CategoryOptions,
  FeedType,
  GetPostFeedMetadataPageRequest,
  GetPostFeedPageRequest,
  HashtagOptions,
  PostFeedPage,
  Post,
  TagOptions,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import {
  ENTITY_TYPE_POSTS,
  EXPERIMENTS,
  NormalizedCategory,
  SECTION_CATEGORY,
  SECTION_HOMEPAGE,
  fetchAppData,
  fetchAppDataSuccess,
  fetchTPASettingsSuccess,
  fetchTagsSuccess,
  getCategoryBySlug,
  getCategoryIds,
  normalizeCategory,
  resolveId,
  isExperimentEnabled,
  Section,
  TpaSettingsPayload,
} from '@wix/communities-blog-client-common';
import {
  fetchArchiveFailure,
  fetchArchiveRequest,
  fetchArchiveSuccess,
} from '../../common/actions/fetch-archive';
import {
  fetchCategoryPostsFailure,
  fetchCategoryPostsRequest,
  fetchCategoryPostsSuccess,
} from '../../common/actions/fetch-category-posts';
import {
  fetchFeedPostsFailure,
  fetchFeedPostsRequest,
  fetchFeedPostsSuccess,
} from '../../common/actions/fetch-feed-posts';
import {
  fetchHashtagPostsFailure,
  fetchHashtagPostsRequest,
  fetchHashtagPostsSuccess,
} from '../../common/actions/fetch-hashtag-posts';
import {
  fetchTagPostsFailure,
  fetchTagPostsRequest,
  fetchTagPostsSuccess,
} from '../../common/actions/fetch-tag-posts';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';

import {
  getLanguageCode,
  getQueryLocale,
} from '../../common/selectors/locale-selectors';
import { getPageSize } from '../../common/selectors/pagination-selectors';
import {
  enhancePostsWithMetadata,
  normalizePosts,
} from '../../common/services/post-utils';
import { setUser } from '../../common/store/auth/set-user';
import { fetchCategoriesSuccess } from '../../common/store/categories/fetch-categories';
import {
  fetchTranslations,
  fetchTranslationsSuccess,
} from '../../common/store/translations/translations-actions';
import { AppState } from '../../common/types';
import { FeedPageThunkAction } from '../types';

type FeedTypeOptions = {
  categoryOptions?: CategoryOptions;
  tagOptions?: TagOptions;
  hashtagOptions?: HashtagOptions;
  archiveOptions?: ArchiveOptions;
};

type FetchFeedRenderModelParams = {
  includeContent: boolean;
  includeInitialPageData?: boolean;
  language?: string;
  page: number;
  pageSize?: number;
  translationsName?: string;
} & FeedTypeOptions;

export const fetchFeedRenderModel = fetchBaseRenderModel({
  feedType: FeedType.ALL_POSTS,
  onInit: ({ dispatch, page }) => {
    dispatch(fetchFeedPostsRequest(ENTITY_TYPE_POSTS, page));
  },
  onSuccess: ({ state, posts, entityCount, page, pageSize }) => {
    return fetchFeedPostsSuccess(
      normalizePosts({
        state,
        posts,
        blogCategoryIds: getCategoryIds(state),
        origin: '/v3/posts',
      }),
      {
        page,
        entityType: ENTITY_TYPE_POSTS,
        entityCount,
        pageSize,
      },
    );
  },
  onError: ({ page }) => {
    return fetchFeedPostsFailure(ENTITY_TYPE_POSTS, page);
  },
});

export const fetchFeedPostsWithAdapterPromisified = createPromisifiedAction(
  fetchFeedRenderModel,
  () => null,
  (response) => response.status,
);

export const fetchCategoryFeedRenderModel = fetchBaseRenderModel({
  feedType: FeedType.CATEGORY,
  onInit: ({ dispatch, page, state, categoryOptions }) => {
    if (!categoryOptions?.id) {
      return;
    }

    dispatch(
      fetchCategoryPostsRequest({
        categoryId: getCategoryBySlug(state, categoryOptions.id)?.id,
        page,
      }),
    );
  },
  onSuccess: ({
    state,
    posts,
    entityCount,
    page,
    pageSize,
    categoryOptions,
  }) => {
    if (!categoryOptions?.id) {
      return;
    }

    const categoryId = resolveId(getCategoryBySlug(state, categoryOptions.id));

    return fetchCategoryPostsSuccess(
      normalizePosts({
        state,
        posts,
        blogCategoryIds: [...getCategoryIds(state), categoryId],
        origin: '/v3/posts',
      }),
      {
        categoryId,
        page,
        entityCount,
        pageSize,
      },
    );
  },
  onError: ({ page, status, state, categoryOptions }) => {
    if (!categoryOptions?.id) {
      return;
    }

    const categoryId = resolveId(getCategoryBySlug(state, categoryOptions.id));

    return fetchCategoryPostsFailure(
      { error: { status }, categoryId, page },
      { categoryId },
    );
  },
});

export const fetchCategoryPostsWithAdapterPromisified = createPromisifiedAction(
  fetchCategoryFeedRenderModel,
  () => null,
  (response) => response.status,
);

export const fetchTagFeedRenderModel = fetchBaseRenderModel({
  feedType: FeedType.TAG,
  onInit: ({ dispatch, page }) => {
    dispatch(fetchTagPostsRequest({ page }));
  },
  onSuccess: ({ state, posts, entityCount, page, pageSize, tagId }) => {
    return fetchTagPostsSuccess(
      normalizePosts({
        state,
        posts,
        blogCategoryIds: getCategoryIds(state),
        origin: '/v3/posts',
      }),
      { tagId, page, entityCount, pageSize },
    );
  },
  onError: ({ page, status, tagOptions }) => {
    return fetchTagPostsFailure(
      { error: { status }, tagId: tagOptions?.id, page },
      { tagId: tagOptions?.id },
    );
  },
});

export const fetchTagPostsWithAdapterPromisified = createPromisifiedAction(
  fetchTagFeedRenderModel,
  () => null,
  (response) => response.status,
);

export const fetchHashtagFeedRenderModel = fetchBaseRenderModel({
  feedType: FeedType.HASHTAG,
  onInit: ({ dispatch, page, hashtagOptions }) => {
    dispatch(
      fetchHashtagPostsRequest({ hashtag: hashtagOptions?.hashtag, page }),
    );
  },
  onSuccess: ({
    state,
    posts,
    entityCount,
    page,
    pageSize,
    hashtagOptions,
  }) => {
    return fetchHashtagPostsSuccess(
      normalizePosts({
        state,
        posts,
        blogCategoryIds: getCategoryIds(state),
        origin: '/v3/posts',
      }),
      { page, entityCount, pageSize, hashtag: hashtagOptions?.hashtag },
    );
  },
  onError: () => {
    return fetchHashtagPostsFailure();
  },
});

export const fetchHashtagPostsWithAdapterPromisified = createPromisifiedAction(
  fetchHashtagFeedRenderModel,
  () => null,
  (response) => response.status,
);

export const fetchArchiveFeedRenderModel = fetchBaseRenderModel({
  feedType: FeedType.ARCHIVE,
  onInit: ({ dispatch, page, archiveOptions }) => {
    dispatch(
      fetchArchiveRequest({
        page,
        year: archiveOptions?.year,
        month: archiveOptions?.month,
      }),
    );
  },
  onSuccess: ({ state, posts, entityCount, page, pageSize }) => {
    return fetchArchiveSuccess(
      normalizePosts({
        state,
        posts,
        blogCategoryIds: getCategoryIds(state),
        origin: '/v3/posts',
      }),
      { page, entityCount, pageSize },
    );
  },
  onError: () => {
    return fetchArchiveFailure();
  },
});

export const fetchArchivePostsWithAdapterPromisified = createPromisifiedAction(
  fetchArchiveFeedRenderModel,
  () => null,
  (response) => response.status,
);

type InitPayload = {
  dispatch: ThunkDispatch<any, any, any>;
  page: number;
  state: AppState;
} & FeedTypeOptions;

type SuccessPayload = {
  state: Partial<AppState>;
  posts: Post[];
  entityCount: number;
  page: number;
  pageSize: number;
  tagId?: string;
} & FeedTypeOptions;

type ErrorPayload = {
  page: number;
  status: number;
  state: AppState;
} & FeedTypeOptions;

type FeedData = {
  response: Omit<PostFeedPage, 'posts' | 'categories'> & {
    categories: NormalizedCategory[];
    posts: Post[];
  };
  metadata: {
    total: number;
    pageSize: number;
  };
};

function fetchBaseRenderModel(context: {
  feedType: FeedType;
  onInit: (payload: InitPayload) => any;
  onSuccess: (payload: SuccessPayload) => any;
  onError: (payload: ErrorPayload) => any;
}) {
  return ({
      includeContent,
      includeInitialPageData,
      language,
      page = 1,
      pageSize: defaultPageSize,
      translationsName,
      categoryOptions,
      tagOptions,
      hashtagOptions,
      archiveOptions,
    }: FetchFeedRenderModelParams): FeedPageThunkAction =>
    async (dispatch, getState, { httpClient, wixCodeApi, compId, flowAPI }) => {
      try {
        const state = getState();

        context.onInit({
          dispatch,
          page,
          state,
          categoryOptions,
          tagOptions,
          hashtagOptions,
          archiveOptions,
        });

        const { metadata, response } = await fetchFeed();
        const tagId = response.tags?.find((t) => t.slug === tagOptions?.id)?.id;

        return Promise.all(
          includeInitialPageData
            ? [
                response.currentUser?.id
                  ? dispatch(setUser(response.currentUser))
                  : Promise.resolve(),
                dispatch(
                  fetchTPASettingsSuccess(
                    response.settings as TpaSettingsPayload,
                  ),
                ),
                dispatch(fetchCategoriesSuccess(response.categories)),
                dispatch(fetchAppDataSuccess(response.appData)),
                dispatch(fetchTranslationsSuccess(response.translations)),
                dispatch(fetchTagsSuccess(response.tags)),
                dispatch(
                  context.onSuccess({
                    tagId,
                    page,
                    state: {
                      categories: response.categories,
                      translations: response.translations ?? {},
                    },
                    posts: response.posts,
                    entityCount: metadata.total,
                    pageSize: metadata.pageSize,
                    categoryOptions,
                    tagOptions,
                    hashtagOptions,
                    archiveOptions,
                  }),
                ),
              ]
            : [
                dispatch(
                  context.onSuccess({
                    tagId,
                    page,
                    state,
                    posts: response.posts,
                    entityCount: metadata.total,
                    pageSize: metadata.total,
                    categoryOptions,
                    tagOptions,
                    hashtagOptions,
                    archiveOptions,
                  }),
                ),
              ],
        );
      } catch (err) {
        return Promise.all([
          dispatch(
            context.onError({
              page,
              status: (err as any)?.response?.status,
              state: getState(),
              categoryOptions,
              tagOptions,
              hashtagOptions,
              archiveOptions,
            }),
          ),

          // Load appData and translations as fallback
          dispatch(fetchAppData(undefined)),
          dispatch(fetchTranslations(language)),
        ]);
      }

      async function getFeed(params: ReturnType<typeof makeRequestParams>) {
        const [feedResponse, metadataResponse] = await Promise.all([
          httpClient
            .request(getPostFeedPage(params.feed))
            .then((r) => r.data)
            .catch((error) => {
              if (error?.response?.status) {
                wixCodeApi.seo.setSeoStatusCode(error.response.status);
              } else {
                wixCodeApi.seo.setSeoStatusCode(500);
              }
            }),
          httpClient
            .request(getPostFeedMetadataPage(params.metadata))
            .then((r) => r.data),
        ]);

        return { feedResponse, metadataResponse };
      }

      async function fetchFeed(): Promise<FeedData> {
        const params = makeRequestParams();

        const warmupDataKey = `feed-page-${compId}-${JSON.stringify(params)}`;
        const shouldUseWarmupData =
          flowAPI.environment.isSSR &&
          isExperimentEnabled(getState(), EXPERIMENTS.USE_WARMUP_STATE_IN_FEED);

        let data: Awaited<ReturnType<typeof getFeed>>;

        if (shouldUseWarmupData) {
          data = await getFeed(params);
          wixCodeApi.window.warmupData.set(warmupDataKey, data);
        } else {
          data =
            wixCodeApi.window.warmupData.get(warmupDataKey) ??
            (await getFeed(params));
        }

        return {
          response: {
            ...data?.feedResponse?.postFeedPage,
            categories: data?.feedResponse?.postFeedPage?.categories
              ? data.feedResponse.postFeedPage?.categories.map(
                  normalizeCategory,
                )
              : [],
            posts: enhancePostsWithMetadata(
              data?.feedResponse?.postFeedPage?.posts?.posts ?? [],
              data?.metadataResponse?.postFeedMetadataPage?.postMetrics ?? {},
            ),
          },
          metadata: {
            total:
              data?.feedResponse?.postFeedPage?.posts?.metaData?.total ?? 0,
            pageSize: params.feed.pageSize ?? 0,
          },
        };
      }

      function makeRequestParams(): {
        feed: GetPostFeedPageRequest;
        metadata: GetPostFeedMetadataPageRequest;
      } {
        const state = getState();
        const locale = getQueryLocale(state);
        const languageCode = getLanguageCode(state);
        const pageSize =
          defaultPageSize ?? getPageSizeForFeedType(context.feedType);

        return {
          feed: {
            includeContent,
            includeInitialPageData,
            categoryOptions,
            tagOptions,
            hashtagOptions,
            archiveOptions,
            languageCode,
            locale,
            page,
            pageSize,
            translationsName,
            type: context.feedType,
          },
          metadata: {
            page,
            pageSize,
            languageCode,
            locale,
            categoryOptions,
            tagOptions,
            hashtagOptions,
            archiveOptions,
            type: context.feedType,
          },
        };
      }

      function getPageSizeForFeedType(feedType: FeedType): number {
        const feedTypeToSectionMap = {
          [FeedType.ALL_POSTS]: SECTION_HOMEPAGE,
          [FeedType.CATEGORY]: SECTION_CATEGORY,
          [FeedType.TAG]: SECTION_CATEGORY,
          [FeedType.HASHTAG]: SECTION_CATEGORY,
          [FeedType.ARCHIVE]: SECTION_CATEGORY,
          [FeedType.UNKNOWN_FEED_TYPE]: SECTION_CATEGORY,
          [FeedType.POST_LIST_WIDGET]: SECTION_CATEGORY,
        } satisfies Record<FeedType, Section>;

        return getPageSize(getState(), {
          section: feedTypeToSectionMap[feedType],
        });
      }
    };
}
