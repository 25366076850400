import type { AppData } from '../../viewer.app';
import type { PostCategoriesApi } from '../Post Categories/viewer.controller';
import type { PostCountersApi } from '../Post Counters New/types';
import type { PostMetadataApi } from '../Post Metadata/viewer.controller';
import type { PostTagsApi } from '../Post Tags/viewer.controller';
import blocksModel from './model';

const selectors = {
  counters: '#postCountersNew1',
  categories: '#categories1',
  tags: '#tags1',
  metadata: '#metadata1',
} as const;

export default blocksModel.createController((params) => {
  const { $w } = params;
  const appData = params.appData as AppData;

  return {
    pageReady: async () => {
      const ui = {
        categories: $w(selectors.categories) as PostCategoriesApi,
        tags: $w(selectors.tags) as PostTagsApi,
        metadata: $w(selectors.metadata) as PostMetadataApi,
        counters: $w(selectors.counters) as PostCountersApi,
      };

      await appData.subjects.postPageRenderModel.subscribe((model) => {
        ui.categories.initialize(model);
        ui.tags.initialize(model);
        ui.metadata.initialize(model);
        if ('initialize' in ui.counters) {
          ui.counters.initialize(model);
        }
      });
    },
    exports: {
      counters: () => $w(selectors.counters) as PostCountersApi,
    },
  };
});
