import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

const createController = (({ $w }) => {
  const ui = {
    ratings: $w('#ratingsDisplay') as $w.RatingsDisplay,
  } as const;

  return {
    pageReady: async () => {},
    exports: {
      initialize: (renderModel: PostPageRenderModel) => {
        if (renderModel.post.totalRatings === 0) {
          ui.ratings.delete();
        } else {
          // @ts-expect-error undefined used for empty state https://www.wix.com/velo/reference/$w/ratingsdisplay/rating
          ui.ratings.rating = renderModel.post.averageRating || undefined;
          ui.ratings.numRatings = renderModel.post.totalRatings || 0;
        }
      },
    },
  };
}) satisfies CreateController;

export type PostRatingsApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
