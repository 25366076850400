import { bindActionCreators } from 'redux';
import type { IControllerConfig } from '@wix/yoshi-flow-editor';
import {
  fetchAppData,
  fetchTPASettings,
} from '@wix/communities-blog-client-common';

import { tagClicked } from '../../common/actions/tag-clicked';
import { getInstance } from '../../common/controller/helpers';
import { initializeActions as initializeCommonActions } from '../../common/controller/init-actions';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { initializeInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import {
  setLocale,
  setQueryLocaleAction,
} from '../../common/store/locale/locale-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import type {
  AppParams,
  AppStore,
  FedopsLogger,
  PlatformAPIs,
  WixCodeApi,
} from '../../common/types';
import { fetchTagCloudWidgetInitialData } from '../store/tag-cloud.actions';

type InitializeActionsParams = {
  wixCodeApi: WixCodeApi;
  store: AppStore;
  fedopsLogger: FedopsLogger;
};

export function initializeActions({
  wixCodeApi,
  store,
  fedopsLogger,
}: InitializeActionsParams) {
  return {
    ...initializeCommonActions({ wixCodeApi, store, fedopsLogger }),
    ...bindActionCreators(
      {
        tagClicked,
      },
      store.dispatch,
    ),
  };
}

export {
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

type InitializeStoreBaseDataParams = {
  wixCodeApi: WixCodeApi;
  store: AppStore;
  language: string;
  platformAPIs: PlatformAPIs;
  config: IControllerConfig;
  bundleName: string;
  appParams: AppParams;
};

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  appParams,
}: InitializeStoreBaseDataParams) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initializeInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());
  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  await Promise.all([
    store.dispatch(
      fetchTPASettings({
        language: getQueryLocale(store.getState()) || language,
      }),
    ), // TODO: move to adapter
    store.dispatch(fetchAppData()), // TODO: move to adapter
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
  ]);
  await store.dispatch(fetchTagCloudWidgetInitialData());

  await store.dispatch(
    setBasicParams({
      viewMode,
      language,
      biPageNumber: platformAPIs.bi?.pageNumber,
    }),
  );
  await store.dispatch(setAppConfig({ bundleName }));
  await store.dispatch(fetchTopology(instanceId));
}
