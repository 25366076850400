import model from './model';

export default model.createController(({ $w }) => {
  return {
    pageReady: async () => {
      const ui = {
        shareButtonsDefault: $w('#shareButtons2') as $w.ShareButtons,
        shareButtonsWhite: $w('#shareButtons1') as $w.ShareButtons,
      };

      ui.shareButtonsDefault.pageToShare = 'current';
      ui.shareButtonsWhite.pageToShare = 'current';
    },
    exports: {},
  };
});
