import type { Category } from '@wix/ambassador-blog-v3-category/types';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

interface Option {
  _id: string;
  label: string;
  link: string;
}

const selectors = {
  categories: '#categoriesElement',
  categoriesRoot: '#categoriesRoot',
  categoiesRepeaterElement: '#category',
} as const;

const state = new Map();

const createController = ((params) => {
  const { $widget, $w } = params;

  const components = {
    categories: $w(selectors.categories) as $w.Repeater,
    categoriesRoot: $w(selectors.categoriesRoot) as $w.Box,
  };

  const applyCategoriesState = () => {
    const categories = state.get('categories') ?? [];

    const options =
      $widget.props.categoriesToDisplay === 'mainCategory'
        ? [categories[0]]
        : categories;

    if (options.length) {
      components.categories.data = options;

      components.categories.forEachItem(($item, itemData: Option) => {
        $item(selectors.categoiesRepeaterElement).link = itemData.link;
        $item(selectors.categoiesRepeaterElement).label = itemData.label;
        $item(selectors.categoiesRepeaterElement).target = '_self';
      });
    } else {
      components.categoriesRoot.delete();
    }
  };

  const setCategories = (categories: Category[]) => {
    const normalizedCategories = categories.map((category) => {
      const url = category.url!;
      const link = url?.base! + url?.path!;
      return {
        _id: category.id ?? '',
        label: category.label ?? '',
        link,
      };
    });
    state.set('categories', normalizedCategories);
    applyCategoriesState();
  };

  return {
    pageReady: async () => {
      $widget.onPropsChanged(applyCategoriesState);
    },
    exports: {
      initialize: (renderModel: PostPageRenderModel) => {
        if (renderModel.categories) {
          setCategories(renderModel.categories);
        }
      },
    },
  };
}) satisfies CreateController;

export type PostCategoriesApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
