import {
  ENTITY_TYPE_POSTS,
  buildPaginationRequestParams,
  createAction,
  getCategoryIds,
  POST_STATUS,
} from '@wix/communities-blog-client-common';
import { FeedPageThunkAction } from '../../feed-page/types';
import { normalizePosts } from '../services/post-utils';
import { NormalizedPost, PostV2 } from '../types';

export const FETCH_LAST_FEATURED_POST_REQUEST =
  'lastFeaturedPost/FETCH_REQUEST';
export const FETCH_LAST_FEATURED_POST_SUCCESS =
  'lastFeaturedPost/FETCH_SUCCESS';
export const FETCH_LAST_FEATURED_POST_FAILURE =
  'lastFeaturedPost/FETCH_FAILURE';

export const fetchLastFeaturedPostRequest = createAction(
  FETCH_LAST_FEATURED_POST_REQUEST,
);
export const fetchLastFeaturedPostSuccess = createAction(
  FETCH_LAST_FEATURED_POST_SUCCESS,
  (payload: NormalizedPost) => payload,
);
const fetchLastFeaturedPostFailure = createAction(
  FETCH_LAST_FEATURED_POST_FAILURE,
);

export const fetchLastFeaturedPost =
  (preFetch: boolean): FeedPageThunkAction =>
  (dispatch, getState, { request }) => {
    dispatch(fetchLastFeaturedPostRequest());

    const paginationParams = buildPaginationRequestParams(1, 1);
    const queryParams = new URLSearchParams({
      offset: paginationParams.offset.toString(),
      size: paginationParams.size.toString(),
      entityType: ENTITY_TYPE_POSTS,
      status: POST_STATUS.published,
      featuredOnly: 'true',
    });

    const promise = request<PostV2[]>(`/_api/posts?${queryParams}`, {
      parseHeaders: true,
    });

    return preFetch
      ? promise
      : completeFetchLastFeaturedPost(promise)(dispatch, getState, {} as any);
  };

export const completeFetchLastFeaturedPost =
  (
    promise: Promise<{ body: PostV2[] }>,
  ): FeedPageThunkAction<{ body: PostV2[] }> =>
  async (dispatch, getState) => {
    try {
      const { body = [] } = await promise;

      const normalizedPost = normalizePosts({
        state: getState(),
        posts: body,
        blogCategoryIds: getCategoryIds(getState()),
      })[0];

      dispatch(fetchLastFeaturedPostSuccess(normalizedPost));
    } catch (_) {
      dispatch(fetchLastFeaturedPostFailure());
    }
    return promise;
  };
