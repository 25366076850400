import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Comments',
  props: {},
  methods: {
    isCommentsPluginInstalled: {
      description: 'Returns boolean value if comments plugin is installed',
    },
  },
  events: {
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
