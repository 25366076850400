import type { AppData } from '../../viewer.app';
import type { PostCategoriesApi } from '../Post Categories/viewer.controller';
import type { PostCountersApi } from '../Post Counters New/types';
import type { PostMetadataApi } from '../Post Metadata/viewer.controller';
import type { PostRatingsApi } from '../Post Ratings/viewer.controller';
import type { PostTagsApi } from '../Post Tags/viewer.controller';
import type { PostTitleApi } from '../Post Title/viewer.controller';
import blocksModel from './model';

const selectors = {
  counters: '#postCountersNew1',
  categories: '#categories1',
  tags: '#tags1',
  metadata: '#metadata1',
  ratings: '#ratings1',
  heroImage: '#image',
  title: '#title1',
} as const;

export default blocksModel.createController(({ $w, appData: _appData }) => {
  const appData = _appData as AppData;
  return {
    pageReady: async () => {
      const ui = {
        categories: $w(selectors.categories) as PostCategoriesApi,
        title: $w(selectors.title) as PostTitleApi,
        ratings: $w(selectors.ratings),
        metadata: $w(selectors.metadata) as PostMetadataApi,
        counters: $w(selectors.counters) as PostCountersApi,
        tags: $w(selectors.tags) as PostTagsApi,
        heroImage: $w(selectors.heroImage) as $w.Image,
      };

      await appData.subjects.postPageRenderModel.subscribe((model) => {
        if (model?.heroImageUrl) {
          ui.heroImage.src = model.heroImageUrl;
        }

        ui.categories.initialize(model);
        ui.title.initialize(model);

        // WIP: do not show any ratings for now
        ui.ratings.delete();
        // ui.ratings.initialize(model);
        ui.metadata.initialize(model);
        if ('initialize' in ui.counters) {
          ui.counters.initialize(model);
        }
        ui.tags.initialize(model);
      });
    },

    exports: {
      counters: () => $w(selectors.counters) as PostCountersApi,
    },
  };
});
